<template>
  <v-container fluid>
    <app-bar ref="app-bar" @expand-drawer="expandDrawer"></app-bar>
    <app-drawer ref="app-drawer"></app-drawer>
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-container>
</template>
<script>
export default {
  name: 'AppIndex',
  components: {
    appBar: () => import('@/components/core/AppBar'),
    appDrawer: () => import('@/components/core/Drawer'),
    // test: () => import('@/views/admin/Test'),
  },
  computed: {
    accessLevel() {
      return this.$store.state.auth.accessLevel
    },
  },
  methods: {
    expandDrawer(isExpanded) {
      this.$refs['app-drawer'].expand = isExpanded
    },
  },
}
</script>
